// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.drop-align{
  margin-left:75px;
}
.filtericon{
  right: 10px;
  position: absolute;
  display: flex;
  cursor:pointer

}
.picklist-header-block{
  display:flex;
  position: relative;
}
.picklist-title{
  display:flex;
flex:9;

}

.filter_apply{
  background-color: #048732;
  color: #fff;
  border-color: #048732;
}
.reset_filter{
  background-color: #ee392c;
  color: #fff;
  border-color: #ee392c;
}
.filter_contentsize{
  height: 56%;
  overflow: auto;
  z-index: 1002;
  transform-origin: center top;
  top: 207px;
  left: 312px;
   --overlayArrowLeft:0px;
   transform: translateY(0px);
   opacity: 1;
}

`, "",{"version":3,"sources":["webpack://./src/app/all-modules/ess-tabs/IND-ess/IND-employee-hrms/manager-page/timemanager/shiftmapping/shiftmapping.component.css"],"names":[],"mappings":"AAAA;EACE,gBAAgB;AAClB;AACA;EACE,WAAW;EACX,kBAAkB;EAClB,aAAa;EACb;;AAEF;AACA;EACE,YAAY;EACZ,kBAAkB;AACpB;AACA;EACE,YAAY;AACd,MAAM;;AAEN;;AAEA;EACE,yBAAyB;EACzB,WAAW;EACX,qBAAqB;AACvB;AACA;EACE,yBAAyB;EACzB,WAAW;EACX,qBAAqB;AACvB;AACA;EACE,WAAW;EACX,cAAc;EACd,aAAa;EACb,4BAA4B;EAC5B,UAAU;EACV,WAAW;GACV,sBAAsB;GACtB,0BAA0B;GAC1B,UAAU;AACb","sourcesContent":[".drop-align{\r\n  margin-left:75px;\r\n}\r\n.filtericon{\r\n  right: 10px;\r\n  position: absolute;\r\n  display: flex;\r\n  cursor:pointer\r\n\r\n}\r\n.picklist-header-block{\r\n  display:flex;\r\n  position: relative;\r\n}\r\n.picklist-title{\r\n  display:flex;\r\nflex:9;\r\n\r\n}\r\n\r\n.filter_apply{\r\n  background-color: #048732;\r\n  color: #fff;\r\n  border-color: #048732;\r\n}\r\n.reset_filter{\r\n  background-color: #ee392c;\r\n  color: #fff;\r\n  border-color: #ee392c;\r\n}\r\n.filter_contentsize{\r\n  height: 56%;\r\n  overflow: auto;\r\n  z-index: 1002;\r\n  transform-origin: center top;\r\n  top: 207px;\r\n  left: 312px;\r\n   --overlayArrowLeft:0px;\r\n   transform: translateY(0px);\r\n   opacity: 1;\r\n}\r\n\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
