// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.submenu-slidedown {
  display: block;
  transition: display 2s ease-out;
}

.submenu-slideup {
  display: none;
}
.actvieurl{
  color: #40aaeb;
}
.actvie_url{
  color: #f00 ;
}
.active {
  /* Define your styles for the active class here */
  /* For example: */
  color: #f00; /* White text color */
  font-weight: bold; /* Bold text */
}
`, "",{"version":3,"sources":["webpack://./src/app/sidebar/sidebar.component.css"],"names":[],"mappings":"AAAA;EACE,cAAc;EACd,+BAA+B;AACjC;;AAEA;EACE,aAAa;AACf;AACA;EACE,cAAc;AAChB;AACA;EACE,YAAY;AACd;AACA;EACE,iDAAiD;EACjD,iBAAiB;EACjB,WAAW,EAAE,qBAAqB;EAClC,iBAAiB,EAAE,cAAc;AACnC","sourcesContent":[".submenu-slidedown {\r\n  display: block;\r\n  transition: display 2s ease-out;\r\n}\r\n\r\n.submenu-slideup {\r\n  display: none;\r\n}\r\n.actvieurl{\r\n  color: #40aaeb;\r\n}\r\n.actvie_url{\r\n  color: #f00 ;\r\n}\r\n.active {\r\n  /* Define your styles for the active class here */\r\n  /* For example: */\r\n  color: #f00; /* White text color */\r\n  font-weight: bold; /* Bold text */\r\n}\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
