// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#box-1{
  margin-left:40px;
  margin-top:50px;
  width: 380px;
  height:180px;
  border: 1px solid rgb(158, 156, 156);
}
#box-2
{
  margin-left:465px;
  margin-top:-180px;
  width: 590px;
  height: 180px;
   border: 1px solid rgb(158, 156, 156);
}
span{
  padding-left:5px;
}
#effectiveDate{
  padding-left: 150px;
}
#toDate_2{
  padding-left: 163px;
}
#annualCTC_2{
  padding-left: 190px;
}
#monthlyCTC_2{
  padding-left: 185px;
}
.save-btn{
  margin-top: -28px;
}
.form-control {
  height: 31px;
  font-size: 14px;
  width: 63px;
}
.page-wrappers{
  margin-left: 16px;
    margin-right: 12px;

}
`, "",{"version":3,"sources":["webpack://./src/app/all-modules/ess-tabs/IND-ess/IND-employee-payroll/fbp/fbp.component.css"],"names":[],"mappings":"AAAA;EACE,gBAAgB;EAChB,eAAe;EACf,YAAY;EACZ,YAAY;EACZ,oCAAoC;AACtC;AACA;;EAEE,iBAAiB;EACjB,iBAAiB;EACjB,YAAY;EACZ,aAAa;GACZ,oCAAoC;AACvC;AACA;EACE,gBAAgB;AAClB;AACA;EACE,mBAAmB;AACrB;AACA;EACE,mBAAmB;AACrB;AACA;EACE,mBAAmB;AACrB;AACA;EACE,mBAAmB;AACrB;AACA;EACE,iBAAiB;AACnB;AACA;EACE,YAAY;EACZ,eAAe;EACf,WAAW;AACb;AACA;EACE,iBAAiB;IACf,kBAAkB;;AAEtB","sourcesContent":["#box-1{\r\n  margin-left:40px;\r\n  margin-top:50px;\r\n  width: 380px;\r\n  height:180px;\r\n  border: 1px solid rgb(158, 156, 156);\r\n}\r\n#box-2\r\n{\r\n  margin-left:465px;\r\n  margin-top:-180px;\r\n  width: 590px;\r\n  height: 180px;\r\n   border: 1px solid rgb(158, 156, 156);\r\n}\r\nspan{\r\n  padding-left:5px;\r\n}\r\n#effectiveDate{\r\n  padding-left: 150px;\r\n}\r\n#toDate_2{\r\n  padding-left: 163px;\r\n}\r\n#annualCTC_2{\r\n  padding-left: 190px;\r\n}\r\n#monthlyCTC_2{\r\n  padding-left: 185px;\r\n}\r\n.save-btn{\r\n  margin-top: -28px;\r\n}\r\n.form-control {\r\n  height: 31px;\r\n  font-size: 14px;\r\n  width: 63px;\r\n}\r\n.page-wrappers{\r\n  margin-left: 16px;\r\n    margin-right: 12px;\r\n\r\n}\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
