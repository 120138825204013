// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `::ng-deep .only-style-this .ui-calendar .ui-calendar-button {
    width: 303px;
  }`, "",{"version":3,"sources":["webpack://./src/app/all-modules/company/employee-payroll-engine/employee-payroll-engine.component.css"],"names":[],"mappings":"AAAA;IACI,YAAY;EACd","sourcesContent":["::ng-deep .only-style-this .ui-calendar .ui-calendar-button {\r\n    width: 303px;\r\n  }"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
