import { Component } from '@angular/core';
import {SpinnerService} from "../../../../../snipper/spinner.service";


@Component({
  selector: 'app-fbp',
  templateUrl: './fbp.component.html',
  styleUrls: ['./fbp.component.css']
})
export class FbpComponent {
  FBP:any;
  CTC=[];
  payable=[];
  activeIndex : any;
  singleBoxCheck = false;
  showTextbox: boolean = false;
  nameBgColors:any = ["bg-blue-dim","bg-blue-dim","bg-azure-dim","bg-indigo-dim","bg-info-dim","bg-purple-dim","bg-pink-dim","bg-orange-dim","bg-teal-dim","bg-primary-dim","bg-success-dim","bg-warning-dim","bg-danger-dim","bg-secondary-dim","bg-dark-dim","bg-gray-dim","bg-blue-dim","bg-azure-dim","bg-indigo-dim","bg-info-dim","bg-purple-dim","bg-pink-dim","bg-orange-dim","bg-teal-dim","bg-primary-dim","bg-success-dim","bg-warning-dim","bg-danger-dim","bg-secondary-dim","bg-dark-dim","bg-gray-dim"];
  nameBorderColors:any = ["bg-outline-primary","bg-outline-success","bg-outline-info","bg-outline-gray","bg-outline-warning","bg-outline-dark","bg-outline-light","bg-outline-secondary","bg-outline-danger"];

  constructor(private spinnerService: SpinnerService) {
  }
  ngOnInit(): void {
  this.spinnerService.raiseDataEmitterEvent('off');
  this.FBP=[
   {'Pay_Elelement_Type':'Flexi Payroll','Pay_Components':'Food Coupon','Edit':'','Max_Annual_Entitlement':'26400','Existing_Monthly_Plan':'2200','Existing_Annual_Plan':'26400','Paid_Till_Date':'19580','My_FBP_Plan_Per_Month':'2200','Plan_Effective_Date':'1 April 2023','Annual_Plan_For_The_Year':'26400'},
    // {'Pay_Elelement_Type':'Flexi Payroll','Pay_Components':'Gift Card','Edit':'','Max_Annual_Entitlement':'5000','Existing_Monthly_Plan':'416.75','Existing_Annual_Plan':'5001','Paid_Till_Date':'2085','My_FBP_Plan_Per_Month':'416.75','Plan_Effective_Date':'9/1/2023','Annual_Plan_For_The_Year':'5001'},
    // {'Pay_Elelement_Type':'Flexi Payroll','Pay_Components':'Communications','Edit':'','Max_Annual_Entitlement':'84000','Existing_Monthly_Plan':'7000','Existing_Annual_Plan':'7000','Paid_Till_Date':'35000','My_FBP_Plan_Per_Month':'7000','Plan_Effective_Date':'9/1/2023','Annual_Plan_For_The_Year':'84000'},
    {'Pay_Elelement_Type':'Reimbursement','Pay_Components':'Telephone Reimbursement','Edit':'','Max_Annual_Entitlement':'12000','Existing_Monthly_Plan':'1000','Existing_Annual_Plan':'12000','Paid_Till_Date':'8900','My_FBP_Plan_Per_Month':'1000','Plan_Effective_Date':'1 April 2023','Annual_Plan_For_The_Year':'12000'},
    // {'Pay_Elelement_Type':'Reimbursement','Pay_Components':'Fuel Reimbursement','Edit':'','Max_Annual_Entitlement':'0','Existing_Monthly_Plan':'0','Existing_Annual_Plan':'0','Paid_Till_Date':'0','My_FBP_Plan_Per_Month':'0','Plan_Effective_Date':'','Annual_Plan_For_The_Year':'0'},
    // {'Pay_Elelement_Type':'Reimbursement','Pay_Components':'Car Maintenance','Edit':'','Max_Annual_Entitlement':'0','Existing_Monthly_Plan':'0','Existing_Annual_Plan':'0','Paid_Till_Date':'0','My_FBP_Plan_Per_Month':'0','Plan_Effective_Date':'','Annual_Plan_For_The_Year':'0'},
    {'Pay_Elelement_Type':'Reimbursement','Pay_Components':'Leave Travel Allowance','Edit':'','Max_Annual_Entitlement':'57500','Existing_Monthly_Plan':'5591','Existing_Annual_Plan':'57500','Paid_Till_Date':'42649','My_FBP_Plan_Per_Month':'5591','Plan_Effective_Date':'1 April 2023','Annual_Plan_For_The_Year':'57500'}
    // {'Pay_Elelement_Type':'Reimbursement','Pay_Components':'LTA','Edit':'','Max_Annual_Entitlement':'353332','Existing_Monthly_Plan':'0','Existing_Annual_Plan':'0','Paid_Till_Date':'0','My_FBP_Plan_Per_Month':'0','Plan_Effective_Date':'0','Annual_Plan_For_The_Year':'35332'}
  ]

  this.CTC=[
    {'Effective_From_Date':'1 April 2023','Effective_To_Date':'31 March 2024','Annual_CTC':'1150000','Monthly_CTC':'95833'}]

  this.payable=[
    {'Fixed_CTC_Payable_for_the_year_A':'1150000','Fixed_Components_B':'1080500','FBP_for_the_Year_total':'69500'}]
  }

  toggleTextbox(event:any,index:any) {

    if (event.target.checked ==true) {
      this.activeIndex = index;
    } else{
       this.activeIndex = null
    }
  }
 saveGridChanges(event:any,index:any) {
  if (event.target.checked ==true) {
    this.activeIndex = index;
  } else{
     this.activeIndex = null
  }
}

  getAcronym(str:any){
    var matches = str.match(/\b(\w)/g); // ['J','S','O','N']
    matches= matches.slice(0,2);
    var acronym = matches.join(''); // JSON
    return acronym;
  }
  getBgColors(str:any){
    var min = 0;
    var max = 15;
    var index = this.findAlphapositions(str,1);
    return this.nameBgColors[index];
  }
  findAlphapositions(str:any, n:any)
  {
    var NUM = 31;

    return str[0].charCodeAt(0) & NUM;
  }

}
