import { Component, ViewChild } from '@angular/core';
import {SpinnerService} from '../../../../../snipper/spinner.service';
import { PfcontributionComponent } from './pfcontribution/pfcontribution.component';
import { ApiModulesService } from 'src/app/all-modules/api-modules.service';

@Component({
  selector: 'app-provident-fund',
  templateUrl: './provident-fund.component.html',
  styleUrls: ['./provident-fund.component.css']
})
export class ProvidentFundComponent {
  activeTab="pf_contribution";
@ViewChild(PfcontributionComponent)pfcontributionComponent: PfcontributionComponent;

  constructor( private spinnerService: SpinnerService,private apiModulesService:ApiModulesService){}


  ngOnInit()
  {
    this.spinnerService.raiseDataEmitterEvent('off');
   }

   changeTabs(activeTab){

    this.activeTab = activeTab;

   }


}
